import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ReadTopics from "../components/ReadTopics";
import BackgroundImage from "gatsby-background-image";
import ClickTable from "../components/ClickTable";
import { getQueryParam } from "../utils/params";
import theme from "../ui/theme";
import { logError } from "../utils/logger";
import LoadingGif from "../images/old-mobile-form/loader.gif";

const HeroImage = styled(BackgroundImage)`
  background-position: center top;
  font-family: "Montserrat", "Open Sans", arial, sans-serif;
`;

const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  margin: 70px auto;
  max-width: 900px;
  padding: 30px 20px;
  text-align: center;
  @media all and (max-width: 1100px) {
    margin: 70px auto;
  }
  @media all and (max-width: 930px) {
    margin: 70px 10px;
  }
  @media all and (max-width: ${theme.breakpoints.md}) {
    margin: 70px 0px 0px;
  }
`;

const Heading1 = styled.div`
  color: #fff;
  font-size: 45px;
  font-weight: bold;
  em {
    font-style: normal;
    color: #3ac0a4;
  }
  @media all and (max-width: ${theme.breakpoints.md}) {
    font-size: 25px;
    margin: 0 0 1.5rem;
  }
`;

const Heading2 = styled.h2`
  color: #fff;
  font-size: 30px;
  padding: 0;
  @media all and (max-width: ${theme.breakpoints.md}) {
    font-size: 16px;
  }
`;
const Text = styled.p`
  font-size: 20px;
  @media all and (max-width: ${theme.breakpoints.md}) {
    font-size: 16px;
  }
`;
const LogoBlock = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const MatchedLogos = () => {
  const [matches, setMatches] = useState([]);
  let retries = 99;

  useEffect(() => {
    const fetchMatches = async () => {
      const chash = getQueryParam("chash", "");
      const lbeOrigin = window.location.origin.match(/stage/)
        ? "https://stage.leads.fullbeaker.com"
        : "https://leads.fullbeaker.com";
      const lbeResponse = await fetch(`${lbeOrigin}/results?chash=${chash}`);
      const lbeData = await lbeResponse.json();
      if (lbeData.error) {
        console.log("Error from Leads Backend:", lbeData.error);
        return;
      }
      if (lbeData?.auctions?.[0]?.status == "selling") {
        if (--retries > 0) {
          setTimeout(fetchMatches, 1000);
        }
        return;
      }
      const keys = lbeData?.auctions?.[0]?.delivered_to ?? [];
      const svcOrigin = "https://service.travelnursing.org";
      const svcResponse = await fetch(`${svcOrigin}/public/partners`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ keys }),
      });
      const svcData = await svcResponse.json();
      setMatches(svcData);
    };

    fetchMatches();
  }, []);

  if (matches.length < 1) {
    return (
      <LogoBlock>
        <img src={LoadingGif} alt="Loading..." />
      </LogoBlock>
    );
  }

  return (
    <LogoBlock>
      {matches.map((match, idx) => (
        <img
          key={idx}
          height="100"
          style={{ padding: "10px" }}
          src={match.image_url}
          alt={match.name}
        />
      ))}
    </LogoBlock>
  );
};

const TemplateConfirmation = ({ data }) => {
  const firstNameValue =
    typeof window !== "undefined" && sessionStorage.getItem("firstName");
  const isCovid19Campaign = Boolean(
    typeof window !== "undefined" &&
      getQueryParam("covid19Campaign", "") &&
      decodeURIComponent(getQueryParam("covid19Campaign", "")).trim() ===
        "true",
  );

  // We use the {{ name }} placeholder from the WP ACF field
  // and then replace it with the firstName URL query param value.
  // This comes from a WYSIWYG field.
  const getHeading1 = () => {
    if (isCovid19Campaign) {
      return { __html: `${firstNameValue}, thank you for taking action!` };
    }
    if (data.wordpressPage.acf && data.wordpressPage.acf.heading_1) {
      let heading1 = data.wordpressPage.acf.heading_1;

      // this way, rn-thankyou does not get affected
      if (heading1.indexOf("your interest in travel nursing!") === -1) {
        if (firstNameValue) {
          heading1 = `${firstNameValue}, thank you for taking action!`;
        } else {
          heading1 = `Thank you for taking action!`;
        }
      }
      return { __html: heading1 };
    } else {
      logError("Confirmation template ACF headings are missing");
      return { __html: "" };
    }
  };

  const getDescriptionText = () => {
    return "You’ll be contacted by the agencies below whose recruiters will work hard to connect you with the best travel nursing opportunities available. Your adventure awaits.";
  };
  return (
    <Layout templateName="confirmation" headerStyle="transparent">
      <Seo
        title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
        canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
        schemaType="WebPage"
        datePublished={data.wordpressPage.date}
        dateModified={data.wordpressPage.modified}
      />
      <HeroImage fluid={data.formImage.childImageSharp.fluid}>
        <Container>
          <Heading1 dangerouslySetInnerHTML={getHeading1()} />
          <Heading2>
            {data.wordpressPage.acf && data.wordpressPage.acf.heading_2}
          </Heading2>
          <Text>{getDescriptionText()}</Text>
          <MatchedLogos />
        </Container>
      </HeroImage>
      <ClickTable
        instance="63cac64ea911fc9609a3d6a6"
        tracking_id="conf_page_ct"
        id="conf_page_ct"
      />
      {data.wordpressPage.acf && data.wordpressPage.acf.topics && (
        <ReadTopics
          withNorgSection
          heading="Read up on these topics!"
          topics={data.wordpressPage.acf.topics}
          fullAreaLink={true}
          learnMoreLinkArr={[0]}
        />
      )}
    </Layout>
  );
};

export default TemplateConfirmation;

export const query = graphql`
  query TemplateConfirmationQuery($slug: String!) {
    formImage: file(relativePath: { eq: "bg-newyork-skyline.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressPage(slug: { eq: $slug }) {
      content
      modified
      date
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        heading_1
        heading_2
        text
        topics {
          heading
          content
          link
          image {
            alt_text
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
